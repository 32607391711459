
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



import { LoginService } from '../_services/login.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthService implements HttpInterceptor{

  constructor(private loginService: LoginService, private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authToken = localStorage.getItem('authToken');
    let authReq = req;
    if (authToken != '' && authToken != undefined){
      authReq = req.clone({headers: req.headers.set('Authorization', authToken)});
     // console.log("yes cloned ");
    }

    return next.handle(authReq).pipe(catchError(error => {
      console.log('Http error interceptor', error.error);
      if(error.status==401){
       // this.toastService.toast("error","Authorization","expired");
        // this.loginService.clear();
        // this.router.navigate(['/login']);
        this.loginService.logoutUser();
      }
      return observableThrowError(error);
  }));
  }

}
