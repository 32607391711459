'use strict';

// const Host = 'http://localhost:3000/';
// const Host = 'http://192.168.1.12:5000/';
const Host = 'https://vellbricks.herokuapp.com/';

export const get_Service_Layer_Url = Host;
export const socketUrl = Host;
export const login_root_URL = Host + 'login';
export const customers_URL = Host + 'customers';
export const internalusersURL = Host + 'users';  
export const profile_URL = Host + 'profiles';  
export const messageURL = Host + 'message';
export const groupUrl = Host + 'group';
export const files = Host+ 'files';
export const notification = Host+ 'notification';



 