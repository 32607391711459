
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as urls from '../globals/links';
import { data } from '../globals/httpresponse';
import { Observable } from 'rxjs';
// import { NavController } from '@ionic/angular';
// import { MessagingService } from './messaging.service';


interface Data {
  status: string;
  result: any;
  message: string;
}

@Injectable()
export class LoginService {

  constructor(private http: HttpClient,
    // private navCtrl: NavController
    ) { }


  loginUser(formData): Observable<any> {
    const url = urls.login_root_URL;
    const body = formData;
    // console.log(body);
    return this.http.post<any>(url, body).pipe(tap(resp => {
      // console.log(resp);
      if (resp.status == 'success') {
        localStorage.setItem('currentUserId', resp.userObj._id);
        localStorage.setItem('currentUserName', 'Super User');
        localStorage.setItem('customer', resp.userObj.type);
      }

      //  return resp;
    },
      err => {
        console.log('error is ', err);
      }));
  }
  loginInternalUser(formData): Observable<any> {
    const url = urls.login_root_URL;
    const body = formData;
    return this.http.post<any>(url, body);
  }
  signup(formData): Observable<any> {
    const url = urls.get_Service_Layer_Url + 'register';
    const body = formData;
    console.log(url)
    return this.http.post<any>(url, body);
  }
  reset(formData): Observable<any> {
    const url = urls.get_Service_Layer_Url + 'reset';
    const body = formData;
    return this.http.post<any>(url, body);
  }
  logoutUser():void {
    let user_str =localStorage.getItem('user');
    if(user_str && user_str.length > 0 ) {
        let userObj = (JSON.parse(user_str));
        let data={};
        data['organization_id'] = userObj.organization_id;
        data['user_id'] = userObj.user_id;
        data['change'] = {
            'status':'offline'
        }
        // this.socketConversationService.emit('userInfoUpdate',data);
    }
    
    this.clear();
  }
  clear():void {
    // this.socketConversationService.disconnect();
    localStorage.clear();
    // this.navCtrl.navigateRoot(['/login']);
    // this.cleartoken().subscribe(res=>{
    //   localStorage.clear();
    //   this.navCtrl.navigateRoot(['/login']);
    // });
   

   
  }
  isAuthenticated(): boolean {
    if (localStorage.getItem('authToken')) {
      return true;
    } else {
      return false;
    }
  }
  check(Permissions) {
    let isUser = true;
    if (localStorage.getItem('customer')) {
      isUser = false;
    }
    // console.log(Permissions, isUser);
    if (isUser) {
      if (Permissions) {
        let userPermissions = [];
        if (localStorage.getItem('userPermissions')) {
          userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
        }
        userPermissions = userPermissions ? userPermissions : [];
        return Permissions.some(function (element) {
          if (userPermissions.indexOf(element) > -1) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        return true;
      }
    } else {
      console.log('super user')
      return true;
    }
  }
  checkOrganization(values: any): Observable<any> {
    let url = urls.login_root_URL + '/' + values.organization;
    let body =values ;
    return this.http.get<any>(url);
  }
  cleartoken(): Observable<any> {
    const url = urls.internalusersURL + '/current/clear' ;
    const body ={
      token : localStorage.getItem('authToken')
    }
    return this.http.post<any>(url, body);
  }
  verifyOtp(formData): Observable<any> {
    const url = urls.get_Service_Layer_Url + 'verifyuser';
    const body = formData;
    return this.http.post<any>(url, body);
  }
}
